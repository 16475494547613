:root {
  --text-primary: #000;
  --bgPrimary: #fff;
  --bgSecondary: #f9f9f9;
  --pink: #0071bd;
  --light-blue: #e6f7ff;
  --border: #d9d9d9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:hover {
  color: var(--pink) !important;
}

/* Loader */
.loader {
  height: 81vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app {
  display: flex;
  overflow: hidden;
}
/* Navbar */
.nav-container {
  display: flex;
  flex-direction: column;
  background-color: #001529;
  padding: 1rem;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.logo {
  margin-left: 1rem;
  margin-bottom: 0 !important;
}
.logo a {
  color: white;
}

/* Mobile Nav Menu Icon */
.menu-control-container {
  display: none !important;
  position: absolute !important;
  right: 10px !important;
  top: 25px !important;
  font-size: 1.2rem !important;
  background-color: var(--bgSecondary) !important;
  border: none !important;
}

/* Main */
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.routes {
  padding: 1rem;
}

.main-title {
  text-transform: capitalize;
  margin-left: 12px;
}

/* Cryptocurrencies */
.crypto-card-container {
  min-height: 65vh !important;
  display: flex;
  justify-content: center;
}
.crypto-card {
  min-width: 250px;
}
.crypto-card .crypto-image {
  width: 35px;
}

/* CryptoDetails */
.coin-detail-container {
  margin: 1rem;
}

.coin-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--border);
  padding-bottom: 1rem;
  gap: 10px;
}

.coin-heading-container .coin-name {
  font-weight: 900;
  color: var(--pink);
}

.coin-heading-container p {
  font-size: 1rem;
  opacity: 0.9;
}

.select-timeperiod {
  width: 80px !important;
  margin: 1.5rem !important;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  gap: 40px;
}

.stats-container h2 {
  font-weight: 700;
  font-size: 1.4rem;
  margin-top: 20px;
  color: var(--pink);
}

/* Description */
.coin-desc {
  font-size: 1rem;
  margin-top: 1rem;
}

.coin-details-heading {
  font-weight: 700 !important;
  margin-top: 20px !important;
  color: var(--pink) !important;
}

.coin-stats {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  font-size: 1rem;
  opacity: 0.9;
  padding: 20px;
}

.coin-stats-name {
  display: flex;
  gap: 10px;
  font-size: 1rem;
}

.stats {
  font-weight: 800;
}

.coin-value-statistics-heading p {
  font-size: 1rem;
  opacity: 0.9;
}

/* Links */
.coin-links {
  padding-top: 1.5rem;
  flex: 0.5;
}

.coin-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding: 20px;
}

.link-name {
  text-transform: capitalize;
  font-size: 1rem;
}

.coin-link a {
  color: var(--pink);
  font-weight: 700;
  font-size: 1rem;
}

.coin-link:hover,
.coin-stats:hover {
  background-color: var(--bgSecondary);
}

.select-news {
  width: 180px;
}
.news-card {
  min-height: 300px !important;
}
.news-image-container {
  display: flex !important;
  justify-content: space-between !important;
}
.news-title {
  width: 70%;
}

.news-image-container .img {
  width: 100px;
  height: 100px;
}
.news-card p {
  color: black;
  margin: 10px 0px !important;
}
.provider-container {
  display: flex;
  justify-content: space-between;
}
.provider-name {
  margin-left: 10px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  color: #0071bd;
}
.chart-title {
  color: #0071bd !important;
}
.price-container {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}
.price-change {
  font-weight: 900 !important;
}
.current-price {
  margin-top: 0px !important;
  font-weight: 900 !important;
}
.home-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.show-more {
  margin-top: 0px !important;
}
.exchange-image {
  margin: 0px 10px !important;
}
.search-crypto {
  /* margin: 20px auto 30px auto; */
  margin-top: 20px;
  margin-bottom: 30px;
  width: 250px;
}

.footer {
  display: fixed;
  bottom: 0;
  background-color: #001529;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .app {
    flex-direction: column;
    overflow: hidden;
  }

  .main {
    flex: 1;
    width: 100vw;
    margin-top: 90px;
  }

  .nav-container {
    height: 8vh;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: var(--bgSecondary);
  }

  .menu-control-container {
    display: block !important;
  }

  .ant-menu {
    position: absolute;
    right: 0px;
  }
  .home-title {
    font-size: 1.4rem !important;
  }
  .show-more {
    font-size: 1.3rem !important;
  }
}

@media screen and (max-width: 1000px) {
  .stats-container {
    flex-direction: column;
  }
  .coin-desc-link {
    flex-direction: column;
  }
  .stats-container h2 {
    margin-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  .coin-links {
    padding: 0px;
  }
  .coin-detail-container {
    margin: 0;
  }
}
@media screen and (max-width: 500px) {
  .heading {
    margin-top: 20px;
  }
}
